<template>
    <div class="addAddress">
        <headBack>
            <template v-slot:title>
                <div class='title'>
                    添加上门地址
                </div>
            </template>
        </headBack>
        <div class='header_cont'></div>
        <div class='contenter'>
            <div class='list'>
                <div class='name'>选择地区</div>
                <div class='dec' @click="state.show = true">
                    {{adressText?adressText:"省，市，区，街道"}}

                    <van-icon name="arrow" class='arrow' />
                </div>
            </div>
            <div class='list'>
                <div class='name'>街道/小区</div>
                <div class='dec'>
                    <van-field v-model="jiedaovalue" class='input_text' placeholder="街道/小区" input-align="right" @focus='jiedaofocus' ref='jiedao' @update:model-value='jiedaoupdate'/>
                </div>
            </div>

            <div class='list' v-if="baidu_list.length ==0 ">
                <div class='name'>详细地址</div>
                <div class='dec'>
                    <van-field v-model="detailvalue" class='input_text' placeholder="详细地址" input-align="right" />
                </div>
            </div>
            <!-- <div class='list'>
                <div class='name'>设为默认地址</div>
                <div class='dec'>
                    <van-switch v-model="checked" active-color="#00C291" size='25' />
                </div>
            </div> -->
        </div>
        <div class='btn_cont mt80' v-if="baidu_list.length == 0">
            <!-- <div class='my_button' @click='router.push({name:"addAddress"})'>添加新地址</div> -->
            <van-button type="primary" block round color='#00C291' @click="saveAddress">保存</van-button>
        </div>

        <van-popup v-model:show="state.show" round position="bottom">
            <van-cascader v-model="state.cascaderValue" title="请选择所在地区" :options="options1" active-color="#00C291" @close="state.show = false" @finish="onFinish" @change="onChange" />
        </van-popup>
        <div class='baidu_list' v-if="baidu_list.length > 0">
            <div class='tips'>您要找的是不是</div>
            <div class='list_item' v-for='(item,index) in baidu_list' :key='index' @click='addressclick(item.address,item.location)'>
              <div class='title'>{{item.name}}</div>
              <div class='dero'>{{item.address}}</div>
            </div>
        </div>
        <div id="container" v-show='false'></div>

    </div>

</template>

<script>
import { onMounted, ref, computed, reactive } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import axios from 'axios'
import { Toast } from 'vant';
import jsonp from '@assets/js/jsonp'
import loginApi from "@axios/login";
import BMap from 'BMap'
export default {
  name: "addAddress",
  components: {},
  requestData: {
    function: "searchAddressDetail",
    provinceId: "",
    cityId: ""
  },
  // options2: [],
  data() {
    return {
      map : '',
      point : {},
      jiedaovalue : '',
      baidu_list : [],
      adressText : '',
      adressCity : '',
      adressArr : [],
      requestData: {
        function: "searchAddressDetail",
        provinceId: "",
        cityId: ""
      },
      options1: [],
      local : '',
      detailvalue : '',
      citycode : ''
    };
  },
  methods: {
    addressclick(val,point){
      let _this = this;
      //ak = uoyXSaX5BoRlqIFLTUy0zW5KgjTPZ85q  DYpsv2XONSoURA3TAhGeZL872G0Mp1Go
      jsonp(`https://api.map.baidu.com/reverse_geocoding/v3/?ak=DYpsv2XONSoURA3TAhGeZL872G0Mp1Go&output=json&location=${point.lat},${point.lng}&callback=baiduCallBack`).then((res)=>{
          _this.citycode = res.result.cityCode;
          this.jiedaovalue = val;
          this.point = point;
          this.baidu_list = [];
      })
    },
    jiedaoupdate(value){
      let _this = this;
      jsonp(`https://api.map.baidu.com/place/v2/suggestion?query=${value}&region=${this.addressCity}&city_limit=true&output=json&ak=DYpsv2XONSoURA3TAhGeZL872G0Mp1Go&callback=baiduCallBack`).then((res)=>{
       console.log('----------')
       console.log(res)
       console.log('----------')
        _this.baidu_list = res.result;
      })
      console.log('ceshi')
      console.log(this.adressCity)
      console.log(value);
      // //百度地图筛选
      // let _this = this;
      // this.local = new BMap.LocalSearch(this.adressText,
      //       {onSearchComplete(results){
      //         console.log(results)
      //         if(results.Br){
      //           _this.baidu_list = results.Br;
      //         }
      //         if(results.Cr){
      //             _this.baidu_list = results.Cr;
      //         }
      //         if(results.Ir){
      //             _this.baidu_list = results.Ir;
      //         }
      //           if(results.Vr){
      //             _this.baidu_list = results.Vr;
      //           }
      //       }});
      // this.local.search(this.adressText + value);
    },
    jiedaofocus(){
      if(!this.adressText){
        Toast('请先选择省市区');
        this.$refs.jiedao.blur();
        return
      }
      if(this.local) this.local.search(this.adressText + this.jiedaovalue);
    },
    getCities(formData) {
      loginApi.loginAction(formData).then(res => {

        let data = res.data;
        data.forEach(function(item) {
          item.text = item.name;
          item.value = item.pid;
        });
        this.options1 = data;
        // let options2 = data;


      });
    },
    saveAddress(){
      if(!this.citycode){
        Toast('请重新输入街道/小区')
        this.jiedaovalue = '';
        return;
      }
        let formData = new FormData();

        formData.append("data",JSON.stringify({
            "function":"saveAddress",
            "userid":this.$store.state.uid,
            "token":this.$store.state.token,
            "address":this.adressText,
            "doorplate":this.jiedaovalue,
            "addressDetail":this.detailvalue,
            "longitude":this.point.lng + '',
            "latitude":this.point.lat + '',
            "cityCode":this.citycode + '',
            "version":this.$store.state.version,
            "newversion":this.$store.state.newversion
        }));
        formData.append("encryption",false);
            loginApi.loginAction(formData).then(res => {
              this.$router.back();
            })
    },
    onChange(value) {


      if (value.tabIndex == 0) {
        let requestData = {
          function: "searchAddressDetail",
          provinceId: String(value.value),
          cityId: ""
        };
        let formData = new FormData();
        formData.append("data", JSON.stringify(requestData));
        formData.append("encryption", false);
        loginApi.loginAction(formData).then(res => {
   console.log('res',res)
          res.data.forEach(function(item) {
            item.text = item.name;
            item.value = item.citycode;
          });

          this.options1[value.value - 1].children = res.data;

        });
      }
      if(value.tabIndex == 1){
          let selecteData = value.selectedOptions;
          console.log('1',selecteData)
                  let requestData = {
          function: "searchAddressDetail",
          provinceId: "",
          cityId: String(selecteData[1].pid)
        };
        let formData = new FormData();
        formData.append("data", JSON.stringify(requestData));
        formData.append("encryption", false);
        loginApi.loginAction(formData).then(res => {

          res.data.forEach(function(item) {
            item.text = item.name;
            item.value = item.pid;
          });


          this.options1[selecteData[0].pid-1].children.forEach(function(item){
              if(item.name == selecteData[1].name){
                  item.children = res.data
              }
          })

          // this.options1[value.value - 1].children = res.data;

        });

      }
    },
    onFinish(selectedOptions) {
      let so = selectedOptions;
      console.log('so',so);
      if(so.selectedOptions.length >= 3){
          this.adressArr = so.selectedOptions;
          this.state.show = false;
          this.adressText = so.selectedOptions[0].name + ' ' +so.selectedOptions[1].name + ' ' + so.selectedOptions[2].name
          this.addressCity =so.selectedOptions[1].name;
      }

    }
  },
  mounted() {
    let formData = new FormData();
    formData.append("data", JSON.stringify(this.requestData));
    formData.append("encryption", false);

    this.getCities(formData);
    this.map = new BMap.Map("container");
    var point = new BMap.Point(116.404, 39.915);
    this.map.centerAndZoom(point, 15);

  },

  setup() {
    let router = useRouter();
    const store = useStore();
    const route = useRoute();
    let checked = ref(false);

    const state = reactive({
      show: false,
      fieldValue: "",
      cascaderValue: ""
    });
    // 选项列表，children 代表子选项，支持多级嵌套
    let options = [
      {
        text: "浙江省",
        value: "330000",
        children: [{ text: "杭州市", value: "330100" }]
      },
      {
        text: "江苏省",
        value: "320000",
        children: [{ text: "南京市", value: "320100" }]
      }
    ];
    let requestData = {
      function: "searchAddressDetail",
      provinceId: "",
      cityId: ""
    };
    // let options2 = options2;
    // console.log(options2);

    return {
      options,
      // options2,
      checked,
      state
      //   onFinish
    };
  }
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.tips{
  background: #f8f9fa;
  height: 40px;
  line-height: 40px;
  padding-left: 15px;
  color: #525a66;
  font-size: 30px;
  padding-left:50px;
  padding-top:20px;
  padding-bottom:20px;
}
.list_item{
  padding:20px 50px;
  .title{
    font-size:35px;
    color: #888;
  }
  .dero{
    color: #888;
    font-size: 30px;
  }
}
.baidu_list{
  width: 100%;
  background: #fff;
}
.input_text {
  width: 400px;
  text-align: right;
  font-size: 30px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #979797;
  padding: 0 !important;
}

.addAddress {
  height: 100%;
  background: #faf9f9;
}
.contenter {
  background: #fff;
  padding: 0 45px;
  box-shadow: 0px 2px 2px 2px rgba(230, 230, 230, 0.2),
    0px 0px 2px 0px rgba(178, 178, 178, 0.12);
  .list {
    padding: 30px 21px;
    font-size: 34px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ededed;
    .dec {
      font-size: 30px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #979797;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .arrow {
        margin-left: 20px;
      }
    }
  }
}
#container{
  height: 300px;
  height: 300px;
}
</style>
